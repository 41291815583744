import { navigate } from "gatsby"
import React from "react"

import Layout from "../components/Layout"
import { isBrowser, isLoggedIn } from "../utils/auth"

const Index = () => {
  if (isBrowser) {
    if (!isLoggedIn()) {
      // If we’re not logged in, redirect to the login page.
      navigate(`/app/login`)
      return null
    } else {
      // Otherwise, head to profile .
      navigate(`/app/customer-selection`)
      return null
    }
  }

  return <Layout></Layout>
}

export default Index
